@import "minima/skins/dark";
@import "minima/initialize";

figure {
    img { 
        margin: auto;
    }
    figcaption {
        text-align: center;
    }
}

img.mermaid {
    display: flex;
    margin: auto;
}

@import "tags.scss";