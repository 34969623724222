/* from the tale theme, modified
 * https://github.com/chesterhow/tale/blob/master/_sass/tale/_tags.scss 
 */

.tags {
  &-header {
    &-title {
      margin: 1rem 0;
      text-align: center;
    }
  }

  &-clouds {
    text-align: center;

    a {
      display: inline-block;
      margin: 0 0.1rem 0.2rem;
      padding: 0.2rem 0.5rem;
      background: $brand-color-dark;
      border-radius: 5px;
      color: $text-color;
      text-decoration: none;

      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-item {
    &-icon {
      height: 1rem;
    }

    &-label {
      display: inline-block;
      margin: 2rem 0 0.5rem;
    }
  }

  &-post {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    &-title {
      color: $text-color;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-meta {
      color: darken($color: $text-color, $amount: 0.05);
      text-align: right;
      white-space: nowrap;
    }

    &:hover,
    &:active {
      .tags-post-line {
        width: 3rem;
      }

      .tags-post-meta {
        color: darken($color: $text-color, $amount: 0.05);
      }
    }
  }
}